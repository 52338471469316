<div mat-dialog-content class="subtitle-dialog" *ngIf="languages.length">
  <form [formGroup]="form" fxLayout="column" fxFlex="100">
    <div fxLayout="column" fxLayoutGap="5px">

      <mat-form-field fxFlex="100">
        <mat-label>{{'SELECT_LANGUAGE' | translate}}</mat-label>
        <mat-select [(value)]="selectedLanguage" required formControlName="languageCode">
          <mat-option *ngFor="let language of languages" [value]="language">
            {{(language | languageName) | translate}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="languageCodeControl.hasError('required')">
          {{'LANGUAGE_IS_REQUIRED' | translate}}
        </mat-error>
      </mat-form-field>

      <div fxFlex="100">
        <app-file-upload [progress]="uploadProgress"
                         [acceptedFileExtension]="subtitleExtension"
                         [allowedFileName]="'Subtitle'"
                         (selectedFile)="onFileSelected($event)">
        </app-file-upload>
      </div>
    </div>
  </form>
</div>

<div mat-dialog-content fxLayoutAlign="center center" *ngIf="!languages.length">
  <h4>{{'VOD_MAX_SUBTITLES' | translate}}</h4>
</div>

<div mat-dialog-actions class="subtitle-dialog-actions">
  <button mat-raised-button color="primary"
          *ngIf="languages.length"
          (click)="onFormSubmit(form.value)">
    {{'UPLOAD' | translate}}
  </button>
  <button mat-raised-button color="accent" [mat-dialog-close]="false">
    {{'CANCEL' | translate}}
  </button>
</div>
