import { NgModule } from '@angular/core';

import { LoginComponent } from './login.component';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseSharedModule } from '../../../@fuse/shared.module';
import { loginRouting } from './login.routing';
import { CoreSharedModule } from '../../core/core-shared.module';

@NgModule({
  declarations: [
    LoginComponent
  ],
  imports: [
    CommonModule,
    loginRouting,
    MaterialModule,
    FlexLayoutModule,
    FuseSharedModule,
    CoreSharedModule,
  ]
})

export class LoginModule {
}
