import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ForgotPasswordComponent } from './forgot-password.component';
import { ForgotPasswordModule } from './forgot-password.module';

export const forgotPasswordRoutes: Routes = [
  {
    path: '',
    component: ForgotPasswordComponent
  },
];

export const forgetPasswordRouting: ModuleWithProviders<ForgotPasswordModule> = RouterModule.forChild(forgotPasswordRoutes);
