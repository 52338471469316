import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FuseProgressBarService } from '../../../@fuse/components/progress-bar/progress-bar.service';
import { tap } from 'rxjs/operators';

@Injectable()
export class ProgressBarInterceptor implements HttpInterceptor {

  constructor(private fuseProgressBarService: FuseProgressBarService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.fuseProgressBarService.show();

    return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.fuseProgressBarService.hide();
        }
      },
      (err: any) => {
        this.fuseProgressBarService.hide();
      }));
  }
}
