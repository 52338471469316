import { NgModule } from '@angular/core';
import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';

@NgModule({
  declarations: [
    FusePerfectScrollbarDirective
  ],
  imports: [],
  exports: [
    FusePerfectScrollbarDirective
  ]
})
export class FuseDirectivesModule {
}
