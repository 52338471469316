import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserRolePipe } from './shared/pipe/user-role.pipe';
import { UserRoleDirective } from './shared/directive/user-role.directive';
import { TruncateStringPipe } from './shared/pipe/truncate-string.pipe';
import { LoadButtonDirective } from './shared/directive/load-button.directive';

@NgModule({
  declarations: [
    UserRolePipe,
    UserRoleDirective,
    LoadButtonDirective,
    TruncateStringPipe
  ],
  exports: [
    UserRolePipe,
    UserRoleDirective,
    LoadButtonDirective,
    TruncateStringPipe
  ],
  imports: [
    CommonModule
  ],
  providers: [
    TruncateStringPipe
  ]
})
export class CoreSharedModule {
}
