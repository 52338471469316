import { Component, OnInit } from '@angular/core';
import { ReportService } from '../services/report.service';
import { ReportInterface } from '../model/report-interface';
import { takeUntil } from 'rxjs/operators';
import { Unsubscribe } from '../../../core/helpers/unsubscribe';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent extends Unsubscribe implements OnInit {
  numberOfCustomers: number;
  activeSubscriptions: number;
  numberOfLinearChannels: number;
  numberOfVods: number;
  numberOfAods: number;

  constructor(private reportingService: ReportService) {
    super();
  }

  ngOnInit(): void {
    this.getNumberOfCustomersReport();
    this.getActiveSubscriptions();
    this.getLinearChannels();
    this.getNumberOfVods();
    this.getNumberOfAods();
  }

  getNumberOfCustomersReport(): void {
    this.reportingService.getReport('customers')
      .pipe(takeUntil(this.ngDestroyed$))
      .subscribe((data: ReportInterface) => {
        this.numberOfCustomers = data.value;
      });
  }

  getActiveSubscriptions(): void {
    this.reportingService.getReport('subscriptions')
      .pipe(takeUntil(this.ngDestroyed$))
      .subscribe((data: ReportInterface) => {
        this.activeSubscriptions = data.value;
      });
  }

  getLinearChannels(): void {
    this.reportingService.getReport('linear-channels')
      .pipe(takeUntil(this.ngDestroyed$))
      .subscribe((data: ReportInterface) => {
        this.numberOfLinearChannels = data.value;
      });
  }

  getNumberOfVods(): void {
    this.reportingService.getReport('vods')
      .pipe(takeUntil(this.ngDestroyed$))
      .subscribe((data: ReportInterface) => {
        this.numberOfVods = data.value;
      });
  }

  getNumberOfAods(): void {
    this.reportingService.getReport('tracks')
      .pipe(takeUntil(this.ngDestroyed$))
      .subscribe((data: ReportInterface) => {
        this.numberOfAods = data.value;
      });
  }
}
