import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ReportInterface } from '../model/report-interface';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  private readonly baseUrl: string = `${environment.backendBaseUrl}/api/reports`;

  constructor(private http: HttpClient) {
  }

  getReport(report: string): Observable<ReportInterface> {
    return this.http.get<ReportInterface>(`${this.baseUrl}/${report}`);
  }
}
