import { UserRolesEnum } from '../../enums/user-roles.enum';
import { FuseNavigation } from '../../../../@fuse/types';

export const navigation: FuseNavigation[] = [
  {
    id: 'quick-menu',
    title: 'Quick Menu',
    type: 'group',
    roles: [UserRolesEnum.ADMIN, UserRolesEnum.REPORTING, UserRolesEnum.ASSET_MANAGER],
    children: [
      {
        id: 'dashboard',
        title: 'MENU.DASHBOARD',
        type: 'item',
        icon: 'home',
        url: '/dashboard',
        roles: [UserRolesEnum.ADMIN, UserRolesEnum.REPORTING, UserRolesEnum.ASSET_MANAGER],
      },
      {
        id: 'new-customer',
        title: 'MENU.NEW_CUSTOMER',
        type: 'item',
        icon: 'home',
        roles: [UserRolesEnum.ADMIN],
      },
      // {
      //   id: 'check-status',
      //   title: 'MENU.CHECK_STATUS',
      //   type: 'item',
      //   icon: 'home',
      //   url: '/check-status',
      //   hidden: true
      // },
      // {
      //   id: 'reports',
      //   title: 'MENU.REPORTS',
      //   type: 'item',
      //   icon: 'home',
      //   url: '/reports',
      //   hidden: false
      // },
      {
        id: 'customer-subscription-managment',
        title: 'MENU.CUSTOMER_SUBSCRIPTION_MANAGEMENT',
        type: 'group',
        roles: [UserRolesEnum.ADMIN],
        children: [
          {
            id: 'package',
            title: 'MENU.PACKAGES',
            type: 'item',
            icon: 'face',
            url: '/packages/list',
            roles: [UserRolesEnum.ADMIN]
          },
          {
            id: 'customers',
            title: 'MENU.CUSTOMERS',
            type: 'collapsable',
            icon: 'home',
            roles: [UserRolesEnum.ADMIN],
            children: [
              {
                id: 'customers-list',
                title: 'MENU.CUSTOMERS',
                type: 'item',
                icon: 'face',
                url: '/customers/list',
                roles: [UserRolesEnum.ADMIN]
              },
              {
                id: 'customers-list',
                title: 'MENU.CUSTOMER_GROUPS',
                type: 'item',
                icon: 'face',
                url: '/customers/group',
                roles: [UserRolesEnum.ADMIN]
              },
              {
                id: 'notifications',
                title: 'MENU.NOTIFICATIONS',
                type: 'item',
                icon: 'home',
                url: '/customers/notifications',
                roles: [UserRolesEnum.ADMIN]
              }
            ]
          }
        ]
      },
      {
        id: 'content-management',
        title: 'MENU.CONTENT_MANAGEMENT',
        type: 'group',
        roles: [UserRolesEnum.ADMIN, UserRolesEnum.ASSET_MANAGER],
        children: [
          {
            id: 'tv-channels',
            title: 'MENU.TV_CHANNELS',
            type: 'collapsable',
            icon: 'tv',
            roles: [UserRolesEnum.ADMIN, UserRolesEnum.ASSET_MANAGER],
            children: [
              {
                id: 'tv-channel-list',
                title: 'MENU.TV_CHANNELS',
                type: 'item',
                icon: 'tv',
                url: '/tv-channels/list',
                roles: [UserRolesEnum.ADMIN, UserRolesEnum.ASSET_MANAGER]
              },
              {
                id: 'tv-channel-category',
                title: 'MENU.TV_CHANNEL_CATEGORIES',
                type: 'item',
                icon: 'tv',
                url: '/tv-channels/category',
                roles: [UserRolesEnum.ADMIN, UserRolesEnum.ASSET_MANAGER]
              },
              {
                id: 'tv-channel-group',
                title: 'MENU.TV_CHANNEL_GROUPS',
                type: 'item',
                icon: 'tv',
                url: '/tv-channels/group',
                roles: [UserRolesEnum.ADMIN, UserRolesEnum.ASSET_MANAGER]
              },
              {
                id: 'epg-information',
                title: 'MENU.EPG_INFORMATION',
                type: 'item',
                icon: 'information',
                url: '/epg-information',
                roles: [UserRolesEnum.ADMIN, UserRolesEnum.ASSET_MANAGER]
              },
            ]
          },
          {
            id: 'vod',
            title: 'MENU.VOD',
            type: 'collapsable',
            icon: 'face',
            roles: [UserRolesEnum.ADMIN, UserRolesEnum.ASSET_MANAGER],
            children: [
              {
                id: 'vod-list',
                title: 'MENU.MOVIES_VIDEOS',
                type: 'item',
                icon: 'home',
                url: '/vod/list',
                roles: [UserRolesEnum.ADMIN, UserRolesEnum.ASSET_MANAGER]
              },
              {
                id: 'vod-group',
                title: 'MENU.MOVIES_VIDEO_GROUP',
                type: 'item',
                icon: 'home',
                url: '/vod/vod-groups',
                roles: [UserRolesEnum.ADMIN, UserRolesEnum.ASSET_MANAGER]
              },
              {
                id: 'vod-genre',
                title: 'MENU.MOVIES_VIDEO_GENRE',
                type: 'item',
                icon: 'home',
                url: '/vod/vod-genre',
                roles: [UserRolesEnum.ADMIN, UserRolesEnum.ASSET_MANAGER]
              },
              {
                id: 'tv-series-list',
                title: 'MENU.TV_SERIES',
                type: 'item',
                icon: 'tv',
                url: '/tv-series/list',
                roles: [UserRolesEnum.ADMIN, UserRolesEnum.ASSET_MANAGER]
              }
            ]
          },
          {
            id: 'aod',
            title: 'MENU.AOD',
            type: 'collapsable',
            icon: 'music_note',
            roles: [UserRolesEnum.ADMIN, UserRolesEnum.ASSET_MANAGER],
            children: [
              {
                id: 'aod-artist',
                title: 'MENU.ARTISTS',
                type: 'item',
                icon: 'mic',
                url: '/aod/artists',
                roles: [UserRolesEnum.ADMIN, UserRolesEnum.ASSET_MANAGER]
              },
              {
                id: 'aod-album',
                title: 'MENU.ALBUMS',
                type: 'item',
                icon: 'library_music',
                url: '/aod/albums',
                roles: [UserRolesEnum.ADMIN, UserRolesEnum.ASSET_MANAGER]
              },
              {
                id: 'aod-tracks',
                title: 'MENU.TRACKS',
                type: 'item',
                icon: 'album',
                url: '/aod/tracks',
                roles: [UserRolesEnum.ADMIN, UserRolesEnum.ASSET_MANAGER]
              },
              {
                id: 'aod-genre',
                title: 'MENU.AOD_GENRES',
                type: 'item',
                icon: 'queue_music',
                url: '/aod/aod-genre',
                roles: [UserRolesEnum.ADMIN, UserRolesEnum.ASSET_MANAGER]
              },
              {
                id: 'aod-labels',
                title: 'MENU.LABELS',
                type: 'item',
                icon: 'recent_actors',
                url: '/aod/labels',
                roles: [UserRolesEnum.ADMIN, UserRolesEnum.ASSET_MANAGER]
              },
            ]
          }
        ]
      },
      {
        id: 'system-settings-and-management',
        title: 'MENU.SYSTEM_SETTINGS_AND_MANAGEMENT',
        type: 'group',
        roles: [UserRolesEnum.ADMIN],
        children: [
          {
            id: 'system-overview',
            title: 'MENU.SYSTEM_OVERVIEW',
            type: 'item',
            icon: 'home',
            url: '/system-settings/system-overview',
            roles: [UserRolesEnum.ADMIN]
          },
          {
            id: 'security',
            title: 'Security',
            type: 'collapsable',
            icon: 'lock',
            hidden: true,
            roles: [UserRolesEnum.ADMIN, UserRolesEnum.REPORTING],
            children: [
              {
                id: 'ip-address-restrictions',
                title: 'IP Address restrictions',
                type: 'item',
                icon: 'home',
                url: '/ip-address-restrictions',
                roles: [UserRolesEnum.ADMIN]
              },
              {
                id: 'access-control',
                title: 'Access Control',
                type: 'item',
                icon: 'home',
                url: '/access-control',
                roles: [UserRolesEnum.ADMIN]
              },
            ]
          },
          {
            id: 'users',
            title: 'MENU.USERS',
            type: 'item',
            icon: 'home',
            url: '/system-settings/users',
            roles: [UserRolesEnum.ADMIN]
          },
          {
            id: 'company-settings',
            title: 'MENU.COMPANY_SETTINGS',
            type: 'item',
            icon: 'home',
            url: '/system-settings/company-settings',
            roles: [UserRolesEnum.ADMIN]
          },
          {
            id: 'api',
            title: 'API',
            type: 'item',
            icon: 'home',
            url: '/system-settings/api',
            hidden: true,
            roles: [UserRolesEnum.ADMIN]
          },
          {
            id: 'reports',
            title: 'MENU.REPORTS',
            icon: 'reports',
            type: 'item',
            url: '/reports',
            roles: [UserRolesEnum.ADMIN, UserRolesEnum.REPORTING],
          },
        ]
      },
    ]
  }
];
