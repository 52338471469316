import { Pipe, PipeTransform } from '@angular/core';
import { UserRolesEnum } from '../../enums/user-roles.enum';

@Pipe({
  name: 'userRole'
})
export class UserRolePipe implements PipeTransform {

  transform(value: string, ...args: any[]): string {
    const userRole: typeof UserRolesEnum = UserRolesEnum;

    switch (value) {
      case userRole.ADMIN:
        return 'Admin';
      case userRole.ASSET_MANAGER:
        return 'Asset Manager';
      case userRole.REPORTING:
        return 'Reporting';

      default:
        return 'Unknown User Role';
    }
  }

}
