import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { VodStream } from '../../../content-management/vod/model/vod-stream.interface';
import { VodBackendService } from '../../../content-management/vod/services/vod-backend.service';
import { takeUntil } from 'rxjs/operators';
import { Unsubscribe } from '../../../../core/helpers/unsubscribe';

@Component({
  selector: 'app-add-edit-vod-stream',
  templateUrl: './add-edit-vod-stream.component.html',
  styleUrls: ['./add-edit-vod-stream.component.scss']
})
export class AddEditVodStreamComponent extends Unsubscribe implements OnInit {
  form: FormGroup;
  vodStream: VodStream;
  dialogRef: MatDialogRef<AddEditVodStreamComponent>;
  streamingSourceCdns: any[];

  constructor(private formBuilder: FormBuilder,
              private vodBackendService: VodBackendService) {
    super();
  }

  ngOnInit(): void {
    this.buildForm();
    this.getAllStreamingSourceCdns();
    this.setFormValues();
  }

  onFormSubmit(formValue: VodStream): void {
    if (formValue) {
      this.dialogRef.close(formValue);
    }
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      id: [null],
      streamingSourceId: [null],
      url: [null],
      token: [null],
      tokenUrl: [null],
      drm: [null],
    });
  }

  private getAllStreamingSourceCdns(): void {
    this.vodBackendService
      .getAllStreamingSourceCdns()
      .pipe(takeUntil(this.ngDestroyed$))
      .subscribe((streamingSourceCdns: any[]) => {
        this.streamingSourceCdns = streamingSourceCdns;
      });
  }

  private setFormValues(): void {
    if (this.vodStream) {
      this.form.patchValue(this.vodStream);
      if (this.vodStream.streamingSource) {
        this.streamingSourceControl.patchValue(this.vodStream.streamingSource.id);
      }
    }
  }

  private get streamingSourceControl(): AbstractControl {
    return this.form.get('streamingSourceId');
  }
}
