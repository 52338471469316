import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthService } from '../../core/auth/auth.service';
import { FuseSidebarService } from '../../../@fuse/components/sidebar/sidebar.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ToolbarComponent implements OnInit {
  fullName: string;
  currentLanguage = localStorage.getItem('currentLanguage') || 'sq';

  constructor(private fuseSidebarService: FuseSidebarService,
              private authService: AuthService,
              private translateService: TranslateService) {
  }

  ngOnInit(): void {
    this.getUserInfo();
  }

  logout(): void {
    this.authService.logout();
  }

  toggleSidebarOpen(key): void {
    this.fuseSidebarService.getSidebar(key).toggleOpen();
  }

  search(value): void {
    console.log(value);
  }

  changeLanguage() {
    this.currentLanguage = this.currentLanguage == 'en' ? 'sq' : 'en';
    this.translateService.use(this.currentLanguage);
    localStorage.setItem('currentLanguage', this.currentLanguage);
  }

  private getUserInfo(): void {
    this.fullName = this.authService.jwtToken.fullName;
  }
}
