import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddEditVodSubtitleComponent } from '../content-management/vod/vod/add-edit-vod-dialog/add-edit-vod-subtitle/add-edit-vod-subtitle.component';
import { MaterialModule } from '../../material.module';
import { AddEditVodStreamComponent } from './components/add-edit-vod-stream/add-edit-vod-stream.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { SubtitleCodePipe } from '../../core/shared/pipe/subtitle-code.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { PreviewImageDialogComponent } from './components/preview-image-dialog/preview-image-dialog.component';
import { NoDataComponent } from './components/no-data/no-data.component';
import { ReportWidgetCardComponent } from './components/report-widget-card/report-widget-card.component';

@NgModule({
  declarations: [
    AddEditVodSubtitleComponent,
    AddEditVodStreamComponent,
    ConfirmationDialogComponent,
    FileUploadComponent,
    SubtitleCodePipe,
    PreviewImageDialogComponent,
    NoDataComponent,
    ReportWidgetCardComponent
  ],
  exports: [
    FileUploadComponent,
    SubtitleCodePipe,
    NoDataComponent,
    ReportWidgetCardComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule,
  ]
})
export class SharedModule {
}
