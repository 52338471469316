<div id="login" fxLayout="column">
  <div id="login-form-wrapper" fxLayout="column" fxLayoutAlign="center center">
    <div id="login-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">
      <div class="logo">
        <img src="assets/images/logos/noon_black.svg">
      </div>
      <div class="title">LOGIN TO YOUR ACCOUNT</div>
      <form name="loginForm" [formGroup]="form">

        <mat-form-field appearance="outline">
          <mat-label>Username</mat-label>
          <input matInput formControlName="username">
          <mat-icon matSuffix class="secondary-text">account_box</mat-icon>
          <mat-error *ngIf="form.get('username').hasError('required')">
            Username is required
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Password</mat-label>
          <input matInput type="password" formControlName="password">
          <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
          <mat-error>
            Password is required
          </mat-error>
        </mat-form-field>

        <div class="error-message">
          <span *ngIf="errorMessage">Invalid Login or password.</span>
          <span *ngIf="errorMessageToken">Invalid Token. Please write your username and password.</span>
        </div>

        <div class="remember-forgot-password" fxLayout="row" fxLayout.xs="column"
             fxLayoutAlign="space-between center">
          <mat-checkbox [formControl]="rememberMeForm" class="remember-me" aria-label="Remember Me">
            Remember Me
          </mat-checkbox>

          <a class="forgot-password" [routerLink]="'/forgot-password'">
            Forgot Password?
          </a>
        </div>

        <button mat-raised-button color="accent" class="submit-button" aria-label="LOG IN"
                loadButtonOnHttpCall
                [disabled]="form.invalid"
                (click)="login()">
          LOGIN
        </button>
      </form>
    </div>

  </div>
</div>
