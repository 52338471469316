<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
  <app-report-widget-card *ngIf="numberOfVods" reportName="{{'NUMBER_OF_VOD'}}" [reportValue]="numberOfVods" fxFlex="30">
  </app-report-widget-card>
  <app-report-widget-card *ngIf="numberOfLinearChannels" reportName="{{'NUMBER_OF_LIVE_TV_CHANNELS'}}" [reportValue]="numberOfLinearChannels"
                          fxFlex="30">
  </app-report-widget-card>
  <app-report-widget-card *ngIf="numberOfAods" reportName="{{'NUMBER_OF_AOD'}}" [reportValue]="numberOfAods" fxFlex="30">
  </app-report-widget-card>
</div>

<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
  <app-report-widget-card *ngIf="numberOfCustomers" reportName="{{'NUMBER_OF_CUSTOMERS'}}" [reportValue]="numberOfCustomers" fxFlex="30">
  </app-report-widget-card>
  <app-report-widget-card *ngIf="activeSubscriptions" reportName="{{'ACTIVE_SUBSCRIPTIONS'}}" [reportValue]="activeSubscriptions" fxFlex="30">
  </app-report-widget-card>
</div>
