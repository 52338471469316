import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { delay, filter, take, takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { AuthService } from '../../../core/auth/auth.service';

@Component({
  selector: 'navbar-vertical-style-1',
  templateUrl: './style-1.component.html',
  styleUrls: ['./style-1.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NavbarVerticalStyle1Component implements OnInit, OnDestroy {
  @ViewChild(FusePerfectScrollbarDirective, {static: true})

  fuseConfig: any;
  navigation: any;
  fullName: string;
  email: string;

  private _fusePerfectScrollbar: FusePerfectScrollbarDirective;
  private _unsubscribeAll: Subject<any>;

  constructor(private _fuseConfigService: FuseConfigService,
              private _fuseNavigationService: FuseNavigationService,
              private _fuseSidebarService: FuseSidebarService,
              private _router: Router,
              private authService: AuthService) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.getUserInfo();
    this._router.events.pipe(filter((event) => event instanceof NavigationEnd),
      takeUntil(this._unsubscribeAll))
      .subscribe(() => {
          if (this._fuseSidebarService.getSidebar('navbar')) {
            this._fuseSidebarService.getSidebar('navbar').close();
          }
        }
      );

    this._fuseConfigService.config.pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this.fuseConfig = config;
      });

    this._fuseNavigationService.onNavigationChanged.pipe(filter(value => value !== null),
      takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this.navigation = this._fuseNavigationService.getCurrentNavigation();
      });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  set directive(theDirective: FusePerfectScrollbarDirective) {
    if (!theDirective) {
      return;
    }

    this._fusePerfectScrollbar = theDirective;

    this._fuseNavigationService.onItemCollapseToggled
      .pipe(delay(500),
        takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this._fusePerfectScrollbar.update();
      });

    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        take(1))
      .subscribe(() => {
          setTimeout(() => {
            this._fusePerfectScrollbar.scrollToElement('navbar .nav-link.active', -120);
          });
        }
      );
  }

  toggleSidebarOpened(): void {
    this._fuseSidebarService.getSidebar('navbar').toggleOpen();
  }

  toggleSidebarFolded(): void {
    this._fuseSidebarService.getSidebar('navbar').toggleFold();
  }

  private getUserInfo(): void {
    this.fullName = this.authService.jwtToken.fullName;
    this.email = this.authService.jwtToken.email;
  }
}
