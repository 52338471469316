<div class="margin-bottom" *ngIf="progress">
  <mat-progress-bar mode="determinate" color="primary" value="{{progress}}"></mat-progress-bar>
</div>

<div class="file-upload-wrapper">
  <div [ngClass]="fileDrag ? 'file-drag' : 'file-upload-area'" fxLayout="row" fxLayoutAlign="space-between center">
    <ng-container>
      <div class="file-content">
        <span class="file-button mat-elevation-z1">
          {{'CHOOSE' | translate}} {{allowedFileName.toUpperCase() | translate}}
        </span>
        <span class="file-description">{{file ? file.name : 'or drag and drop ' + allowedFileName | lowercase }}</span>
      </div>
    </ng-container>

    <div class="file-upload-image-area" *ngIf="imageUrl" (click)="openPreviewImageDialog(imageUrl)">
      <img [src]="imageUrl" alt="imagePreview"/>
    </div>
    <input class="file-input" type="file"
           [accept]="acceptedFileExtensions"
           (dragenter)="fileDrag = onFileDrag($event)"
           (dragleave)="fileDrag = false"
           (change)="onFileSelected($event)">
  </div>
</div>
<mat-error *ngIf="errorMessage">
  {{'ONLY_THESE_FORMATS_ARE_ALLOWED' | translate}}: {{acceptedFileExtensions}}.
</mat-error>
