import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate'
})
export class TruncateStringPipe implements PipeTransform {

  transform(value: string, limit?: number, ...args: any[]): string {
    if (value) {
      const truncateStringLimit = limit ? limit : 30;
      return value.length < truncateStringLimit ? value : value.slice(0, truncateStringLimit) + '...';
    }
  }
}
