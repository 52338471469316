import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-preview-image-dialog',
  templateUrl: './preview-image-dialog.component.html',
  styleUrls: ['./preview-image-dialog.component.scss']
})
export class PreviewImageDialogComponent implements OnInit {
  imageUrl: string;

  constructor() {
  }

  ngOnInit(): void {
  }

}
