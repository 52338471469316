import { Injectable } from '@angular/core';
import { LoginCredentials } from './model/login.credentials';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { mapTo, takeUntil, tap } from 'rxjs/operators';
import { OAuth } from './model/OAuth';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { JwtToken } from './model/jwtToken';
import { Unsubscribe } from '../helpers/unsubscribe';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends Unsubscribe {
  loggedUser: string;
  jwtToken: JwtToken;
  isRememberMeChecked$: Observable<boolean>;
  isRememberMeChecked: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private readonly baseUrl: string = `${environment.backendBaseUrl}/oauth/token`;
  private readonly OAuthSecretKey: string = `${environment.OAuth.client_id}:${environment.OAuth.client_secret}`;
  private readonly access_token: string = 'access_token';
  private readonly refresh_token: string = 'refresh_token';
  private readonly remember_me: string = 'remember_me';
  private readonly httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Basic ' + btoa(this.OAuthSecretKey)
    })
  };

  constructor(private http: HttpClient,
              private router: Router,
              private jwtHelperService: JwtHelperService) {
    super();
    this.isRememberMeChecked$ = this.isRememberMeChecked.asObservable();
    this.doRememberUserLogin();
    this.decodeJwtToken();
  }

  login(loginCredentials: LoginCredentials): Observable<boolean> {
    const params = new HttpParams({
      fromObject: {
        username: loginCredentials.username,
        password: loginCredentials.password,
        grant_type: 'password'
      }
    });

    return this.http.post<OAuth>(`${this.baseUrl}`, params, this.httpOptions)
      .pipe(tap(
        (oAuth: OAuth) => this.doLoginUser(oAuth)),
        mapTo(true)
      );
  }

  logout(): void {
    this.doLogoutUser();
    // todo logout also from backend
  }

  isLoggedIn(): boolean {
    return !!this.getAccessToken();
  }

  refreshToken(): Observable<OAuth> {
    const params = new HttpParams({
      fromObject: {
        refresh_token: this.getRefreshToken(),
        grant_type: 'refresh_token'
      }
    });

    return this.http.post<OAuth>(`${this.baseUrl}`, params, this.httpOptions)
      .pipe(tap((oAuth: OAuth) => this.storeTokens(oAuth)));
  }

  getAccessToken(): string {
    return localStorage.getItem(this.access_token);
  }

  getRememberMeValue(): boolean {
    const rememberMeChecked = !!localStorage.getItem(this.remember_me);
    return !!(rememberMeChecked && this.getRefreshToken());
  }

  doRememberUserLogin(): void {
    this.isRememberMeChecked$
      .pipe(takeUntil(this.ngDestroyed$))
      .subscribe((value: boolean) => {
        if (value) {
          localStorage.setItem(this.remember_me, 'checked');
        } else {
          localStorage.removeItem(this.remember_me);
        }
      });
  }

  getRefreshToken(): string {
    return localStorage.getItem(this.refresh_token);
  }

  hasRole(name: string): boolean {
    return this.jwtToken.authorities.includes(name);
  }

  private decodeJwtToken(): void {
    if (this.getAccessToken()) {
      this.jwtToken = this.jwtHelperService.decodeToken(this.getAccessToken());
    }
  }

  private doLoginUser(oAuth: OAuth): void {
    this.jwtToken = this.jwtHelperService.decodeToken(oAuth.access_token);
    this.loggedUser = this.jwtToken.fullName;
    this.storeTokens(oAuth);
  }

  private doLogoutUser(): void {
    this.loggedUser = null;
    this.removeAccessToken();
    this.router.navigate(['login']);
  }

  private storeTokens(oAuth: OAuth): void {
    localStorage.setItem(this.access_token, oAuth.access_token);
    localStorage.setItem(this.refresh_token, oAuth.refresh_token);
  }

  private removeAccessToken(): void {
    localStorage.removeItem(this.access_token);
  }
}
