import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { fuseAnimations } from '../../../@fuse/animations';
import { AuthService } from '../../core/auth/auth.service';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { LoginCredentials } from '../../core/auth/model/login.credentials';
import { Router } from '@angular/router';
import { Unsubscribe } from '../../core/helpers/unsubscribe';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class LoginComponent extends Unsubscribe implements OnInit {
  form: FormGroup;
  rememberMeForm: FormControl = new FormControl();
  errorMessage: boolean = false;
  errorMessageToken: boolean = false;

  constructor(private formBuilder: FormBuilder,
              private authService: AuthService,
              private router: Router) {
    super();
  }

  ngOnInit(): void {
    this.buildForm();
    this.logout();
    this.rememberMeWasChecked();
    this.onRememberMeFormChanged();
    this.onFormChanged();
    this.listenForIsRememberChecked();
  }

  login(): void {
    if (this.authService.getRememberMeValue() && this.rememberMeForm.value === true) {
      this.authService
        .refreshToken()
        .pipe(takeUntil(this.ngDestroyed$))
        .subscribe(() => {
          this.router.navigate(['']);
        }, () => {
          this.errorMessageToken = true;
        });
    } else {
      const loginCredential: LoginCredentials = {
        username: this.getFormControl('username').value,
        password: this.getFormControl('password').value
      };

      this.authService
        .login(loginCredential)
        .pipe(takeUntil(this.ngDestroyed$))
        .subscribe(() => {
          this.router.navigate(['']);
        }, () => {
          this.errorMessage = true;
        });
    }
  }

  logout(): void {
    this.authService.logout();
  }

  rememberMeWasChecked(): void {
    if (this.authService.getRememberMeValue()) {
      this.getFormControl('username').patchValue('******');
      this.getFormControl('password').patchValue('******');
    }
  }

  private onRememberMeFormChanged(): void {
    this.rememberMeForm.valueChanges
      .pipe(
        distinctUntilChanged(), debounceTime(0),
        takeUntil(this.ngDestroyed$)
      )
      .subscribe((value: boolean) => {
        if (value) {
          this.authService.isRememberMeChecked.next(true);
        } else {
          this.authService.isRememberMeChecked.next(false);
        }
      });
  }

  private listenForIsRememberChecked(): void {
    this.authService.isRememberMeChecked$
      .pipe(takeUntil(this.ngDestroyed$))
      .subscribe((value: boolean) => {
        if (value) {
          this.rememberMeForm.patchValue(true);
          return true;
        } else {
          this.rememberMeForm.patchValue(false);
          return false;
        }
      });
  }

  private onFormChanged(): void {
    this.form.valueChanges
      .pipe(
        distinctUntilChanged(), debounceTime(0),
        takeUntil(this.ngDestroyed$)
      )
      .subscribe(() => {
        if (this.form.dirty) {
          this.errorMessageToken = false;
          this.errorMessage = false;
        }
        if (this.form.dirty && this.authService.getRememberMeValue()) {
          this.authService.isRememberMeChecked.next(false);
          this.getFormControl('username').patchValue(null);
          this.getFormControl('password').patchValue(null);
        }
        if (this.form.dirty && this.authService.getRefreshToken() && !this.rememberMeForm.value) {
          localStorage.removeItem('refresh_token');
        }
      });
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      username: [null, [Validators.required]],
      password: [null, Validators.required]
    });
  }

  private getFormControl(formControlName: string): AbstractControl {
    return this.form.get(formControlName);
  }
}
