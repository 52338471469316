import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FuseSidebarModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';

import { FooterModule } from 'app/layout/components/footer/footer.module';
import { NavbarModule } from 'app/layout/navbar/navbar.module';

import { VerticalLayout1Component } from 'app/layout/layout.component';
import { ToolbarModule } from './toolbar/toolbar.module';

@NgModule({
  declarations: [
    VerticalLayout1Component
  ],
  imports: [
    RouterModule,

    FuseSharedModule,
    FuseSidebarModule,

    FooterModule,
    NavbarModule,
    ToolbarModule
  ],
  exports: [
    VerticalLayout1Component
  ]
})
export class VerticalLayout1Module {
}
