import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseSharedModule } from '../../../@fuse/shared.module';
import { ForgotPasswordComponent } from './forgot-password.component';
import { forgetPasswordRouting } from './forgot-password.routing';


@NgModule({
  declarations: [
    ForgotPasswordComponent
  ],
  imports: [
    CommonModule,
    forgetPasswordRouting,
    MaterialModule,
    FlexLayoutModule,
    FuseSharedModule
  ]
})
export class ForgotPasswordModule {
}
