import { Pipe, PipeTransform } from '@angular/core';
import { VodSubtitleLanguagesEnum } from '../../../main/content-management/vod/vod/add-edit-vod-dialog/add-edit-vod-subtitle/enum/vodSubtitleLanguages-enum';

@Pipe({
  name: 'languageName'
})
export class SubtitleCodePipe implements PipeTransform {

  transform(value: string, ...args: any[]): string {
    const languageCode: typeof VodSubtitleLanguagesEnum = VodSubtitleLanguagesEnum;

    switch (value) {
      case languageCode.EN:
        return 'ENGLISH';
      case languageCode.SQ:
        return 'SHQIP';

      default:
        return 'Unknown language';
    }
  }

}
