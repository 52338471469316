import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { navigation } from 'app/core/shared/navigation/navigation';
import { FuseNavigationService } from '../../@fuse/components/navigation/navigation.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VerticalLayout1Component implements OnInit, OnDestroy {
  fuseConfig: any;
  navigation: any;

  private _unsubscribeAll: Subject<any>;

  constructor(private _fuseConfigService: FuseConfigService,
              private fuseNavigationService: FuseNavigationService,
              private router: Router) {
    this.navigation = navigation;

    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this._fuseConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this.fuseConfig = config;
      });

    this.addNewCustomerShortcut();
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  addNewCustomerShortcut(): void {
    const newCustomerShortcut = {
      id: 'new-customer',
      type: 'item',
      icon: 'home',
      function: () => {
        this.router.navigate(['customers', 'list'], {queryParams: {add: 1}});
      }
    };

    this.fuseNavigationService.updateNavigationItem('new-customer', newCustomerShortcut);
  }
}
