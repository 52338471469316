import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpStatusCode } from '../enums/http-status-code.enum';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private snackBar: MatSnackBar) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        const data: { message: string, customMessage: string, status: number, duration: number } = {
          message: error && error.error && error.error.message ? error.error.message : '',
          customMessage: '',
          status: error.status,
          duration: 5000
        };

        switch (data.status) {
          case HttpStatusCode.INTERNAL_SERVER_ERROR:
            data.customMessage = ('Something went wrong ...');
            break;
          case HttpStatusCode.FORBIDDEN:
          case HttpStatusCode.UNAUTHORIZED:
            data.customMessage = ('Your not Authorized to see this page');
            break;
          case HttpStatusCode.BAD_REQUEST:
            data.customMessage = ('Bad Request');
            break;

          case HttpStatusCode.NOT_FOUND:
            data.customMessage = ('404 Not Found');
            break;

          default:
            data.customMessage = ('Something went wrong ...');
        }

        switch (error.error.error) {
          case 'invalid_token':
            data.customMessage = ('Refreshing Access Token...');
            data.duration = 2000;
            break;
        }
        this.snackBar.open(data.customMessage, 'OK', {horizontalPosition: 'right', duration: data.duration});

        return throwError(error);
      }));
  }
}
