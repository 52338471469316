import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ImageExtensions } from './enum/image-extensions.enum';
import { PreviewImageDialogComponent } from '../preview-image-dialog/preview-image-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent {
  @Input() progress: number;
  @Input() acceptedFileExtension: any;
  @Input() allowedFileName: string;
  @Input() imageUrl: string | ArrayBuffer | null;
  @Output() readonly selectedFile: EventEmitter<File> = new EventEmitter<File>();

  constructor(private dialog: MatDialog) {
  }

  fileDrag: boolean;
  file: File;
  errorMessage: boolean;

  get acceptedFileExtensions(): string {
    if (this.acceptedFileExtension) {
      return Object.values(this.acceptedFileExtension).join(', ');
    }
  }

  onFileSelected(event: any): void {
    this.file = event.target.files[0] as File;
    if (this.validateFileExtension && this.file) {
      this.selectedFile.emit(this.file);
      this.fileDrag = false;
      this.errorMessage = false;
      this.readImage();
    } else {
      this.file = null;
      this.fileDrag = false;
      this.errorMessage = true;
      this.imageUrl = null;
    }
  }

  onFileDrag(event: any): boolean {
    if (event.dataTransfer.types) {
      for (const type of event.dataTransfer.types) {
        if (type === 'Files') {
          return true;
        }
      }
    }
    return false;
  }

  readImage(): void {
    if (this.validateImageExtension) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(this.file);
      fileReader.onload = (_event) => {
        this.imageUrl = fileReader.result;
      };
    }
  }

  openPreviewImageDialog(imageUrl: string): void {
    const dialogRef = this.dialog.open(PreviewImageDialogComponent);
    dialogRef.componentInstance.imageUrl = imageUrl;
  }

  private get validateFileExtension(): boolean {
    if (this.file) {
      const fileExtension: string = this.file.name.substr(this.file.name.lastIndexOf('.'));
      return Object.values(this.acceptedFileExtension).includes(fileExtension);
    }
  }

  private get validateImageExtension(): boolean {
    if (this.file) {
      const fileExtension: string = this.file.name.substr(this.file.name.lastIndexOf('.'));
      return Object.values(ImageExtensions).join(',').includes(fileExtension);
    }
  }
}
