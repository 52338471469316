import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class Unsubscribe implements OnDestroy {
  protected readonly ngDestroyed$: Subject<null> = new Subject<null>();

  ngOnDestroy(): void {
    this.ngDestroyed$.next(null);
  }
}
