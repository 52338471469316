<div id="main">
  <!-- TOOLBAR: Above -->
  <ng-container *ngIf="fuseConfig.layout.toolbar.position === 'above'">
    <ng-container *ngTemplateOutlet="toolbar"></ng-container>
  </ng-container>
  <!-- / TOOLBAR: Above -->

  <div id="container-1" class="container">

    <!-- NAVBAR: Left -->
    <ng-container *ngIf="fuseConfig.layout.navbar.position === 'left'">
      <ng-container *ngTemplateOutlet="leftNavbar"></ng-container>
    </ng-container>
    <!-- / NAVBAR: Left -->

    <div id="container-2" class="container">

      <!-- TOOLBAR: Below fixed -->
      <ng-container *ngIf="fuseConfig.layout.toolbar.position === 'below-fixed'">
        <ng-container *ngTemplateOutlet="toolbar"></ng-container>
      </ng-container>
      <!-- / TOOLBAR: Below fixed -->

      <div id="container-3" class="container" fusePerfectScrollbar
           [fusePerfectScrollbarOptions]="{suppressScrollX: true, updateOnRouteChange : true}">

        <!-- TOOLBAR: Below static -->
        <ng-container *ngIf="fuseConfig.layout.toolbar.position === 'below-static'">
          <ng-container *ngTemplateOutlet="toolbar"></ng-container>
        </ng-container>
        <!-- / TOOLBAR: Below static -->

        <!-- CONTENT -->
        <div class="page-layout blank p-24">
          <router-outlet></router-outlet>
        </div>
        <!-- / CONTENT -->
      </div>
    </div>
  </div>
</div>
<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- @ PARTIALS                                                                                            -->
<!-- ----------------------------------------------------------------------------------------------------- -->

<!-- TOOLBAR -->
<ng-template #toolbar>
  <toolbar *ngIf="!fuseConfig.layout.toolbar.hidden"
           [ngClass]="fuseConfig.layout.toolbar.customBackgroundColor === true ? fuseConfig.layout.toolbar.position + ' ' + fuseConfig.layout.toolbar.background : fuseConfig.layout.toolbar.position">
  </toolbar>
</ng-template>
<!-- / TOOLBAR -->

<!-- LEFT NAVBAR -->
<ng-template #leftNavbar>
  <fuse-sidebar name="navbar" class="navbar-fuse-sidebar"
                [folded]="fuseConfig.layout.navbar.folded"
                lockedOpen="gt-md"
                *ngIf="!fuseConfig.layout.navbar.hidden">
    <navbar [variant]="fuseConfig.layout.navbar.variant" class="left-navbar"></navbar>
  </fuse-sidebar>
</ng-template>
<!-- / LEFT NAVBAR -->
