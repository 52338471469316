import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard.component';
import { DashboardModule } from './dashboard.module';


const dashboardRoutes: Routes = [
  {
    path: '',
    component: DashboardComponent
  },
];

export const dashboardRouting: ModuleWithProviders<DashboardModule> = RouterModule.forChild(dashboardRoutes);
