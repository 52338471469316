import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';

import { navigation } from 'app/core/shared/navigation/navigation';
import { Unsubscribe } from './core/helpers/unsubscribe';

@Component({
  selector: 'app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends Unsubscribe implements OnInit {
  fuseConfig: any;
  navigation: any;

  private _unsubscribeAll: Subject<any>;

  constructor(@Inject(DOCUMENT) private document: any,
              private _fuseConfigService: FuseConfigService,
              private _fuseNavigationService: FuseNavigationService,
              private _fuseSidebarService: FuseSidebarService,
              private _fuseSplashScreenService: FuseSplashScreenService,
              private translateService: TranslateService,
              private _platform: Platform) {
    super();
    this.navigation = navigation;

    this._fuseNavigationService.register('main', this.navigation);

    this._fuseNavigationService.setCurrentNavigation('main');

    if (this._platform.ANDROID || this._platform.IOS) {
      this.document.body.classList.add('is-mobile');
    }

    this._unsubscribeAll = new Subject();

    translateService.addLangs(['en', 'sq']);
    translateService.setDefaultLang('sq');
    const currentLanguage = localStorage.getItem('currentLanguage') || 'en';
    this.translateService.use(currentLanguage);
  }

  ngOnInit(): void {
    this._fuseConfigService.config
      .pipe(takeUntil(this.ngDestroyed$))
      .subscribe((config) => {
        this.fuseConfig = config;

        if (this.fuseConfig.layout.width === 'boxed') {
          this.document.body.classList.add('boxed');
        } else {
          this.document.body.classList.remove('boxed');
        }

        // Color theme - Use normal for loop for IE11 compatibility
        for (const bodyClassName of this.document.body.classList) {
          const className = bodyClassName;

          if (className.startsWith('theme-')) {
            this.document.body.classList.remove(className);
          }
        }

        this.document.body.classList.add(this.fuseConfig.colorTheme);
      });
  }

  toggleSidebarOpen(key): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }
}
