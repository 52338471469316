import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { environment } from '../../../environments/environment';
import { OAuth } from '../auth/model/OAuth';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class AuthTokenHttpInterceptor implements HttpInterceptor {
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private readonly authUrl: string = `${environment.backendBaseUrl}/oauth/token`;

  constructor(private authService: AuthService,
              private router: Router,
              private snackBar: MatSnackBar) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url === this.authUrl) {
      return next.handle(request);
    }

    if (this.authService.getAccessToken()) {
      request = this.setTokenToHeaders(request, this.authService.getAccessToken());
    }

    return next.handle(request).pipe(catchError(error => {
      if (error instanceof HttpErrorResponse && error.status === 401) {
        return this.handleUnauthorizedError(request, next);
      }
      if (error instanceof HttpErrorResponse && error.status === 403) {
        this.router.navigate(['forbidden']);
      }
    }));
  }

  private setTokenToHeaders(request: HttpRequest<any>, token: string): any {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  private handleUnauthorizedError(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.refreshTokenSubject.next(null);

    return this.authService
      .refreshToken()
      .pipe(switchMap((oAuth: OAuth) => {
        this.snackBar.open('Access token has been refreshed', '',
          {
            horizontalPosition: 'right',
            duration: 3000
          });
        this.refreshTokenSubject.next(oAuth.access_token);
        return next.handle(this.setTokenToHeaders(request, oAuth.access_token));
      }))
      .pipe(catchError(error => {
        if (error instanceof HttpErrorResponse && error.error.error_description.includes('expired')) {
          this.router.navigate(['login']);
          this.snackBar.open('Failed to refresh access token, Please login', '',
            {
              horizontalPosition: 'right',
              duration: 8000
            });
          return EMPTY;
        }
      }));
  }
}
