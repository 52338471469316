<mat-toolbar class="p-0 mat-elevation-z1">
  <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">
    <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
      <button mat-icon-button class="navbar-toggle-button" (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
        <mat-icon class="secondary-text">menu</mat-icon>
      </button>
    </div>

    <button mat-icon-button
            (click)="changeLanguage()"
            matTooltipShowDelay="500"
            matTooltip="{{currentLanguage == 'sq' ? 'Application Language: Shqip' : 'Gjuha e Aplikacionit: English'}}">
      <span *ngIf="currentLanguage == 'sq'">SQ</span>
      <span *ngIf="currentLanguage == 'en'">EN</span>
    </button>

    <div class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">
      <button mat-button [matMenuTriggerFor]="userMenu"
              class="user-button">
        <div fxLayout="row" fxLayoutAlign="center center">
          <img class="avatar mr-0 mr-sm-16" src="../../../assets/images/avatars/profile_avatar.png">
          <span class="username mr-12">{{fullName}}</span>
          <span class="username mr-12" *ngIf="!fullName">No Name</span>
          <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
        </div>
      </button>

      <mat-menu #userMenu="matMenu" [overlapTrigger]="false">
        <button mat-menu-item (click)="logout()">
          <mat-icon>exit_to_app</mat-icon>
          <span>Logout</span>
        </button>
      </mat-menu>
    </div>
  </div>
</mat-toolbar>
