import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpEvent, HttpParams } from '@angular/common/http';
import { Vod } from '../model/vod.interface';
import { VodGroup } from '../model/vod-group.interface';
import { VodSubtitle } from '../model/vod-subtitle.interface';
import { VodPackage } from '../model/vod-package.interface';
import { VodStream } from '../model/vod-stream.interface';
import { VodGenre } from '../model/vod-genre';
import { VodMetadata } from '../model/vod-metadata';
import { QueryParamsHelper } from '../../../../core/helpers/query-params-helper';
import { PagedResponse } from '../../../../core/shared/paged-response';

@Injectable({
  providedIn: 'root'
})
export class VodBackendService {
  private readonly baseUrl: string = `${environment.backendBaseUrl}/api/vods`;
  private readonly baseApiUrl: string = `${environment.backendBaseUrl}/api`;

  constructor(private http: HttpClient) {
  }

  // vods
  getAll(data: any): Observable<PagedResponse<Vod>> {
    const params: HttpParams = QueryParamsHelper.setHttpParams(data);
    return this.http.get<PagedResponse<Vod>>(`${this.baseUrl}`, {params});
  }

  getOneById(id: string): Observable<Vod> {
    return this.http.get<Vod>(`${this.baseUrl}/${id}`);
  }

  create(vod: Vod): Observable<Vod> {
    return this.http.post<Vod>(`${this.baseUrl}`, vod);
  }

  update(vod: Vod): Observable<Vod> {
    return this.http.put<Vod>(`${this.baseUrl}/${vod.id}`, vod);
  }

  delete(id: string): Observable<any> {
    return this.http.delete<any>(`${this.baseUrl}/${id}`);
  }

  // search vod meta data
  searchVodMetadata(data: any): Observable<VodMetadata[]> {
    const params: HttpParams = QueryParamsHelper.setHttpParams(data);
    return this.http.get<VodMetadata[]>(`${this.baseUrl}/search`, {params});
  }

  getVodMetadataById(id: string): Observable<Vod> {
    return this.http.get<Vod>(`${this.baseUrl}/search/${id}`);
  }

  uploadPosterImage(vodId: string, formData: FormData): Observable<HttpEvent<any>> {
    const httpOptions: any = {
      reportProgress: true,
      observe: 'events'
    };

    return this.http.put<any>(`${this.baseUrl}/${vodId}/poster-image`, formData, httpOptions);
  }

  uploadBackdropImage(vodId: string, formData: FormData): Observable<HttpEvent<any>> {
    const httpOptions: any = {
      reportProgress: true,
      observe: 'events'
    };

    return this.http.put<any>(`${this.baseUrl}/${vodId}/backdrop-image`, formData, httpOptions);
  }

  // groups
  getAllVodGroups(data: any): Observable<PagedResponse<VodGroup>> {
    const params: HttpParams = QueryParamsHelper.setHttpParams(data);
    return this.http.get<PagedResponse<VodGroup>>(`${this.baseApiUrl}/vod-groups`, {params});
  }

  getOneVodGroupById(id: string): Observable<VodGroup> {
    return this.http.get<VodGroup>(`${this.baseApiUrl}/vod-groups/${id}`);
  }

  createVodGroup(vodGroup: VodGroup): Observable<VodGroup> {
    return this.http.post<VodGroup>(`${this.baseApiUrl}/vod-groups`, vodGroup);
  }

  updateVodGroup(vodGroup: VodGroup): Observable<VodGroup> {
    return this.http.put<VodGroup>(`${this.baseApiUrl}/vod-groups/${vodGroup.id}`, vodGroup);
  }

  deleteVodGroup(id: string): Observable<any> {
    return this.http.delete<any>(`${this.baseApiUrl}/vod-groups/${id}`);
  }

  // genre
  getAllVodGenre(data: any): Observable<PagedResponse<VodGenre>> {
    const params: HttpParams = QueryParamsHelper.setHttpParams(data);
    return this.http.get<PagedResponse<VodGenre>>(`${this.baseUrl}/genres`, {params});
  }

  createVodGenre(vodGenre: VodGenre): Observable<VodGenre> {
    return this.http.post<VodGenre>(`${this.baseUrl}/genres`, vodGenre);
  }

  updateVodGenre(vodGenre: VodGenre): Observable<VodGenre> {
    return this.http.put<VodGenre>(`${this.baseUrl}/genres/${vodGenre.id}`, vodGenre);
  }

  deleteVodGenre(id: string): Observable<any> {
    return this.http.delete<any>(`${this.baseUrl}/genres/${id}`);
  }

  // vod-subtitles
  getAllVodSubtitles(): Observable<VodSubtitle[]> {
    return this.http.get<VodSubtitle[]>(`${this.baseUrl}/subtitles`);
  }

  getOneVodSubtitleById(id: string): Observable<VodSubtitle> {
    return this.http.get<VodSubtitle>(`${this.baseUrl}/subtitles/${id}`);
  }

  uploadSubtitles(companyId: string, formData: FormData): Observable<HttpEvent<any>> {
    const httpOptions: any = {
      reportProgress: true,
      observe: 'events'
    };

    return this.http.post<any>(`${this.baseUrl}/subtitles`, formData, httpOptions);
  }

  updateVodSubtitle(vodSubtitle: VodSubtitle): Observable<VodSubtitle> {
    return this.http.put<VodSubtitle>(`${this.baseUrl}/${vodSubtitle.id}/subtitles`, vodSubtitle);
  }

  deleteVodSubtitle(id: string): Observable<any> {
    return this.http.delete<any>(`${this.baseUrl}/subtitles/${id}`);
  }

  uploadVodBulk(formData: FormData): Observable<HttpEvent<any>> {
    const httpOptions: any = {
      reportProgress: true,
      observe: 'events'
    };

    return this.http.post<any>(`${this.baseUrl}/csv`, formData, httpOptions);
  }

  // vod package
  getAllVodPackages(): Observable<VodPackage[]> {
    return this.http.get<VodPackage[]>(`${this.baseUrl}/packages`);
  }

  getOneVodPackageById(id: string): Observable<VodPackage> {
    return this.http.get<VodPackage>(`${this.baseUrl}/packages/${id}`);
  }

  createVodPackage(vodPackage: any): Observable<VodPackage> {
    return this.http.post<VodPackage>(`${this.baseUrl}/packages`, vodPackage);
  }

  updateVodPackage(vodPackage: VodPackage): Observable<VodPackage> {
    return this.http.put<VodPackage>(`${this.baseUrl}/packages/${vodPackage.id}`, vodPackage);
  }

  deleteVodPackage(id: string): Observable<any> {
    return this.http.delete<any>(`${this.baseUrl}/packages/${id}`);
  }

  // vod stream
  getAllStreams(): Observable<VodStream[]> {
    return this.http.get<VodStream[]>(`${this.baseUrl}/streams`);
  }

  getOneStreamById(id: string): Observable<VodStream> {
    return this.http.get<VodStream>(`${this.baseUrl}/streams/${id}`);
  }

  createStream(vodStream: any): Observable<VodStream> {
    return this.http.post<VodStream>(`${this.baseUrl}/streams`, vodStream);
  }

  updateStream(vodStream: VodStream): Observable<VodStream> {
    return this.http.put<VodStream>(`${this.baseUrl}/streams/${vodStream.id}`, vodStream);
  }

  deleteStream(id: string): Observable<any> {
    return this.http.delete<any>(`${this.baseUrl}/streams/${id}`);
  }


  // get all stremaing source cdns
  getAllStreamingSourceCdns(): Observable<any[]> {
    return this.http.get<any[]>(`${this.baseApiUrl}/streaming-sources`);
  }

}
