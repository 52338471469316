import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from '../../auth/auth.service';

@Directive({
  selector: '[appUserRole]'
})
export class UserRoleDirective implements OnInit {
  userRoles: string[] = [];

  constructor(private templateRef: TemplateRef<any>,
              private authService: AuthService,
              private viewContainer: ViewContainerRef) {
  }

  @Input() set appUserRole(roles: string[]) {
    const userRole = roles.map(item => `ROLE_${item}`);

    if (!userRole || !userRole.length) {
      throw new Error('Roles value is empty or missed');
    }
    this.userRoles = userRole;
  }

  ngOnInit(): void {
    let hasAccess: boolean = false;

    // TODO maybe check if its authorized as well!?
    if (this.userRoles) {
      hasAccess = this.userRoles.some((role: string) => this.authService.hasRole(role));
    }

    if (hasAccess) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
