import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';
import { DashboardComponent } from './dashboard.component';
import { dashboardRouting } from './dashboard.routing';
import { CoreSharedModule } from '../../core/core-shared.module';
import { ReportsComponent } from './reports/reports.component';
import { MaterialModule } from '../../material.module';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
    DashboardComponent,
    ReportsComponent
  ],
  imports: [
    TranslateModule,
    FuseSharedModule,
    dashboardRouting,
    CoreSharedModule,
    MaterialModule,
    SharedModule
  ],
  exports: [
    DashboardComponent
  ]
})

export class DashboardModule {
}
