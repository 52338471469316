<div mat-dialog-content>
  <form [formGroup]="form" fxLayout="column" fxFlex="100">
    <div fxLayout="column" fxLayoutGap="5px">
      <mat-form-field fxFlex="100">
        <mat-label>Streaming Source</mat-label>
        <mat-select formControlName="streamingSourceId">
          <mat-option *ngFor="let streamingSourceCdn of streamingSourceCdns"
                      [value]="streamingSourceCdn.id">{{streamingSourceCdn.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxLayout="column" fxLayoutGap="5px">
      <mat-form-field fxFlex="50">
        <mat-label>Token Url</mat-label>
        <input formControlName="tokenUrl" matInput>
      </mat-form-field>

      <mat-form-field fxFlex="50">
        <mat-label>Url</mat-label>
        <input formControlName="url" matInput>
      </mat-form-field>
    </div>
    <div class="padding-tb-10" fxLayout="column" fxLayoutGap="5px">
      <div fxFlex="50">
        <mat-slide-toggle formControlName="token" labelPosition="before">Token:</mat-slide-toggle>
      </div>
      <div fxFlex="50">
        <mat-slide-toggle formControlName="drm" labelPosition="before">Drm:</mat-slide-toggle>
      </div>
    </div>
  </form>
</div>

<div mat-dialog-actions>
  <button mat-raised-button color="primary"
          (click)="onFormSubmit(form.value)">
    Save
  </button>
  <button mat-raised-button color="accent" [mat-dialog-close]="false">Cancel</button>
</div>
