import { Component, OnInit } from '@angular/core';
import { UserRolesEnum } from '../../core/enums/user-roles.enum';

@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  userRolesEnum: typeof UserRolesEnum = UserRolesEnum;

  constructor() {
  }

  ngOnInit(): void {

  }
}
