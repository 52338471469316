import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-report-widget-card',
  templateUrl: './report-widget-card.component.html',
  styleUrls: ['./report-widget-card.component.scss']
})
export class ReportWidgetCardComponent implements OnInit {
  @Input() reportName: string;
  @Input() reportValue: number;

  constructor() {
  }

  ngOnInit(): void {
  }

}
