import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardModule } from './main/dashboard/dashboard.module';
import { LoginComponent } from './main/login/login.component';
import { VerticalLayout1Component } from './layout/layout.component';
import { ForgotPasswordComponent } from './main/forgot-password/forgot-password.component';
import { NotFoundComponent } from './main/not-found/not-found.component';
import { ForbiddenComponent } from './main/forbbiden/forbidden.component';
import { VodModule } from './main/content-management/vod/vod.module';
import { AuthGuard } from './core/guards/auth.guards';
import { SystemSettingsModule } from './main/system-settings/system-settings.module';
import { CustomersSubscriptionManagementModule } from './main/customer-and-subscription-management/customer/customers-subscription-management.module';
import { IngestDistributionModule } from './main/ingest-and-distribution/ingest-distribution.module';
import { TvChannelsModule } from './main/content-management/tv-channels/tv-channels.module';
import { TvSeriesModule } from './main/content-management/tv-series/tv-series.module';
import { PackagesModule } from './main/customer-and-subscription-management/packages/packages.module';
import { EpgModule } from './main/content-management/epg/epg.module';

const lazyLoadedRoutes: Routes = [
  {
    path: 'dashboard',
    loadChildren: (): Promise<DashboardModule> =>
      import('./main/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'vod',
    loadChildren: (): Promise<VodModule> =>
      import('./main/content-management/vod/vod.module').then(m => m.VodModule)
  },
  {
    path: 'aod',
    loadChildren: (): Promise<VodModule> =>
      import('./main/content-management/aod/aod.module').then(m => m.AodModule)
  },
  {
    path: 'epg-information',
    loadChildren: (): Promise<EpgModule> =>
      import('./main/content-management/epg/epg.module').then(m => m.EpgModule)
  },
  {
    path: 'system-settings',
    loadChildren: (): Promise<SystemSettingsModule> =>
      import('./main/system-settings/system-settings.module').then(m => m.SystemSettingsModule)
  },
  {
    path: 'customers',
    loadChildren: (): Promise<CustomersSubscriptionManagementModule> =>
      import('./main/customer-and-subscription-management/customer/customers-subscription-management.module').then(m => m.CustomersSubscriptionManagementModule)
  },
  {
    path: 'ingest-and-distribution',
    loadChildren: (): Promise<IngestDistributionModule> =>
      import('./main/ingest-and-distribution/ingest-distribution.module').then(m => m.IngestDistributionModule)
  },
  {
    path: 'tv-channels',
    loadChildren: (): Promise<TvChannelsModule> =>
      import('./main/content-management/tv-channels/tv-channels.module').then(m => m.TvChannelsModule)
  },
  {
    path: 'tv-series',
    loadChildren: (): Promise<TvSeriesModule> =>
      import('./main/content-management/tv-series/tv-series.module').then(m => m.TvSeriesModule)
  },
  {
    path: 'packages',
    loadChildren: (): Promise<PackagesModule> =>
      import('./main/customer-and-subscription-management/packages/packages.module').then(m => m.PackagesModule)
  }
];

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'forbidden',
    component: ForbiddenComponent
  },
  {
    path: 'not-found',
    component: NotFoundComponent
  },
  {
    path: '',
    component: VerticalLayout1Component,
    canActivate: [AuthGuard],
    children: [
      ...lazyLoadedRoutes
    ]
  },
  {
    path: '**',
    redirectTo: 'not-found'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
