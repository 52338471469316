import {
  ComponentFactoryResolver,
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewContainerRef
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { FuseProgressBarService } from '../../../../@fuse/components/progress-bar/progress-bar.service';

@Directive({
  selector: '[loadButtonOnHttpCall]'
})

export class LoadButtonDirective implements OnDestroy, OnInit {
  @Input() formValid;

  subscription: Subscription;
  spinner: any;
  originalInnerText: string;

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(private elRef: ElementRef,
              private renderer: Renderer2,
              private componentFactoryResolver: ComponentFactoryResolver,
              private viewContainerRef: ViewContainerRef,
              private fuseProgressBarService: FuseProgressBarService) {
  }

  ngOnInit(): void {
    this.httpCallInProgress();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  httpCallInProgress(): void {
    this.originalInnerText = this.elRef.nativeElement.firstChild.innerHTML;
    this.fuseProgressBarService
      .visible
      .subscribe(visible => {
        this.elRef.nativeElement.disabled = !!visible;
        if ((this.formValid != null)) {
          if ((this.formValid.valid === false)) {
            this.elRef.nativeElement.disabled = true;
          }
        }
      });
  }
}
