import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { VodSubtitle } from '../../../model/vod-subtitle.interface';
import { SubtitleExtension } from '../../../../../shared/components/file-upload/enum/subtitle-extension.enum';
import { VodSubtitleLanguagesEnum } from './enum/vodSubtitleLanguages-enum';
import { VodBackendService } from '../../../services/vod-backend.service';
import { Unsubscribe } from '../../../../../../core/helpers/unsubscribe';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-add-edit-vod-subtitle',
  templateUrl: './add-edit-vod-subtitle.component.html',
  styleUrls: ['./add-edit-vod-subtitle.component.scss']
})

export class AddEditVodSubtitleComponent extends Unsubscribe implements OnInit {
  form: FormGroup;
  vodSubtitle: VodSubtitle[];
  dialogRef: MatDialogRef<AddEditVodSubtitleComponent>;
  languages: string[] = Object.values(VodSubtitleLanguagesEnum);
  selectedLanguage: string;
  subtitleExtension: typeof SubtitleExtension = SubtitleExtension;
  uploadProgress: number;
  selectedFile: File;
  fileUploaded: boolean = false;
  addedSubtitles: VodSubtitle[];
  availableSubtitles: string[] = [];

  constructor(private formBuilder: FormBuilder,
              private vodBackendService: VodBackendService) {
    super();
  }

  ngOnInit(): void {
    this.buildForm();
    this.setFormValues();
    if (this.addedSubtitles) {
      this.addedSubtitles.filter(subtitle => {
        this.languages = this.languages.filter(lang => lang !== subtitle.languageCode);
      });
    }
  }

  get languageCodeControl(): AbstractControl {
    return this.form.get('languageCode');
  }

  onFormSubmit(formValue: VodSubtitle): void {
    if (this.form.valid) {
      this.saveFile(formValue);
    } else {
      this.form.markAllAsTouched();
    }
  }

  onFileSelected(file: File): void {
    this.selectedFile = file;
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      id: [null],
      languageCode: [null, Validators.required]
    });
  }

  private saveFile(formValue: VodSubtitle): void {
    const formData: FormData = new FormData();
    formData.append('languageCode', this.selectedLanguage);
    formData.append('file', this.selectedFile);

    if (this.selectedFile) {
      this.vodBackendService
        .uploadSubtitles(this.selectedLanguage, formData)
        .pipe(takeUntil(this.ngDestroyed$))
        .subscribe((event: any) => {
          this.uploadProgress = Math.round(100 * event.loaded / event.total);
          if (event.body) {
            this.form.patchValue(event.body);
          }
        }, () => {
          // Todo add error message here
        }, () => {
          this.dialogRef.close(this.form.value);
        });
    }
  }

  private setFormValues(): void {
    if (this.vodSubtitle) {
      this.form.patchValue(this.vodSubtitle);
    }
  }
}
