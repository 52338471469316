import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login.component';
import { LoginModule } from './login.module';

const loginRoutes: Routes = [
  {
    path: '',
    component: LoginComponent
  },
];

export const loginRouting: ModuleWithProviders<LoginModule> = RouterModule.forChild(loginRoutes);
