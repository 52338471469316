import { HttpParams } from '@angular/common/http';
import * as moment from 'moment';

export class QueryParamsHelper {
  static setHttpParams(params: any): HttpParams {
    const manualAddedParams: string[] = ['textQuery', 'date', 'sort', 'page'];
    const paramKeys: string[] = Object.keys(params);
    let httpParams: HttpParams = new HttpParams();

    paramKeys.filter(p => !manualAddedParams.includes(p)).forEach(param => {
      if (params[param] !== null && params[param] !== undefined) {
        httpParams = httpParams.set(param, params[param].toString());
      }
    });

    if (params.dateTime) {
      const date = moment(params.dateTime).format();
      const encodedDate = encodeURIComponent(date);
      httpParams = httpParams.set('dateTime', encodedDate);
    }

    if (params.textQuery) {
      httpParams = httpParams.set('text-query', params.textQuery);
    }

    if (params.sort) {
      params.sort.fields.forEach(field => {
        httpParams = httpParams.set('sort', `${field.name},${field.direction}`);
      });
    }

    if (params.page) {
      httpParams = httpParams.set('page', params.page.page);
      httpParams = httpParams.set('size', params.page.size);
    }

    return httpParams;
  }
}
